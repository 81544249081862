import React, { useContext, useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { useDispatch } from 'react-redux';
import { openAlert } from '../../../store/features/modalSlice';
import { MypageContext } from './MypageContext';
import DocumentArrowDownIcon from '@heroicons/react/24/outline/DocumentArrowDownIcon';

const MypageMembershipMember = () => {
  const dispatch = useDispatch();
  const { userInfo } = useContext(MypageContext);
  const [file1, setFile1] = useState<fileData | null>(userInfo?.certificates);
  const [file2, setFile2] = useState<fileData | null>(userInfo?.headShot);

  const putFileInfo = async (
    type: 'headShotImageId' | 'certificationFileId',
    value: string
  ) => {
    try {
      const param = { [type]: value };
      const result = await instance.put<ApiResponse<any>>(
        `/v1/mypage/church-info/certificates`,
        param
      );
      return result.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  const deleteFile = async (fileId: string) => {
    try {
      const result = await instance.delete(`/v1/file/s3/${fileId}`);
      return result.data.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const file1Handler = async (data: fileData) => {
    if (!data) return;
    putFileInfo('certificationFileId', data.id)
      .then(() => {
        if (file1) deleteFile(file1.id);
        setFile1(data);
        dispatch(openAlert({ message: '파일이 업로드 되었습니다.' }));
      })
      .catch((e) => {
        dispatch(openAlert({ message: '파일이 업로드 실패!' }));
        setFile1(null);
      });
  };

  const file2Handler = async (data: fileData) => {
    if (!data) return;

    putFileInfo('headShotImageId', data.id)
      .then(() => {
        if (file2) deleteFile(file2.id);
        setFile2(data);
        dispatch(openAlert({ message: '파일이 업로드 되었습니다.' }));
      })
      .catch((e) => {
        dispatch(openAlert({ message: '파일이 업로드 실패!' }));
        setFile2(null);
      });
  };

  return (
    <div className="flex flex-col gap-8 shadow-card p-4 rounded-lg max-w-[500px] m-auto">
      <section className="flex flex-col gap-4">
        <TextWinds type="title_h2">교인 정보</TextWinds>
        <TextWinds type="title_h4">
          교사 코스 수료 전, 교인 인증 완료하기
        </TextWinds>
        <TextWinds type="content">
          교사 코스를 수료하기 전까지
          <br />
          교인 인증을 위한 교인확인서와 사진을 업로드 해주세요.
        </TextWinds>
      </section>
      <section className="flex flex-col gap-8">
        <div>
          <TextWinds type="content" className="text-black">
            교인확인서
          </TextWinds>
          <TextWinds type="caption1" className="text-black ">
            교회 자체 양식 또는{' '}
            <a
              href={process.env.PUBLIC_URL + '/file/mypage/젭스_교인확인서.pdf'}
              className="text-blue-600 font-bold hover:underline hover:text-blue-800"
              download
            >
              젭스 교인확인서 양식
              <DocumentArrowDownIcon width={16} className="inline" />
            </a>
            을 작성 후 업로드 해주세요.
          </TextWinds>

          <FileComponent
            id="file1"
            uploadType="CERTIFICATE"
            fileData={file1}
            setFileData={file1Handler}
          />
        </div>
        <div>
          <TextWinds type="content" className="text-black">
            본인 사진
          </TextWinds>
          <FileComponent
            id="file2"
            uploadType="CERTIFICATE"
            fileData={file2}
            setFileData={file2Handler}
          />
        </div>
      </section>
      <section className="bg-gray2">
        <ul className="list-disc list-outside ml-4 p-4">
          <li>지원파일 : jpg, png, pdf (최대 10MB)</li>
          <li>
            파일 이름은 공백과 다음 특수문자를 포함할 수 없습니다.
            <br />
            \n, {'{'}, {'}'}, ^, #, %, [, ], \"
          </li>
          <li>
            얼굴이 정확히 식별 가능한 사진으로 업로드해 주세요. (최근 6개월 이내
            촬영)
          </li>
          <li>
            제출 완료된 교인확인서와 본인 사진은 수정이 불가합니다. 수정을
            원하시는 경우 고객센터로 문의주세요.
          </li>
        </ul>
      </section>
    </div>
  );
};

type fileData = {
  id: string;
  origin_file_name: string;
};
const FileComponent = ({
  id,
  uploadType,
  fileData,
  setFileData,
}: {
  id: string;
  uploadType: string;
  fileData: fileData;
  setFileData: (filedata: fileData | undefined) => void;
}) => {
  const putFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file, encodeURIComponent(file.name));
      formData.append('uploadType', uploadType);

      const result = await instance.post<ApiResponse<fileData>>(
        '/v1/file/s3',
        formData,
        {
          headers: {
            Accept: '*/*',
            'Content-Type':
              'multipart/form-data; boundary=<calculated when request is sent>',
          },
        }
      );
      return result.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  const fileHandler = async (file: File) => {
    if (!file) return;
    setFileData(await putFile(file));
  };
  return (
    <>
      <label
        htmlFor={id}
        className="relative flex gap-4 border border-gray4 p-1 rounded-lg group hover:bg-gray2 cursor-pointer"
      >
        <TextWinds type="content" className="p-2 text-gray6">
          {fileData
            ? fileData.origin_file_name
            : '클릭하여 파일을 업로드해주세요.'}
        </TextWinds>
        <div className="!absolute right-[3px] top-[3px] border border-purple5 bg-purple5 group-hover:bg-purple6 p-2 rounded w-fit text-white">
          업로드
        </div>
      </label>
      <input
        type="file"
        name={id}
        id={id}
        className="hidden"
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={(e) => {
          fileHandler(e.target.files![0]);
        }}
      />
    </>
  );
};

export default MypageMembershipMember;
