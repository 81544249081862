import React from 'react';
import { LmsContainer } from '../../../components/layout/LmsLayouts';
import { useParams } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import RecitationResult from '../../../components/recitation/RecitationResult';

const RecitationHistoryDetail = () => {
  const { studentId } = useParams();

  return (
    <LmsContainer
      header={<TextWinds type="title_h3">인증제 내역 상세보기</TextWinds>}
    >
      <RecitationResult studentUid={studentId} />
    </LmsContainer>
  );
};

export default RecitationHistoryDetail;
