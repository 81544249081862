import { ApiResponse } from '../../../constants/types';
import { studentBadgeType } from '../../../constants/types/student-type';
import instance from '../../axios/utils.ts/instance';

/**
 * 학생 뱃지 조회
 */
export const getStudentBadge = async () => {
  const result = await instance.get<ApiResponse<studentBadgeType[]>>(
    // '/v1/student/badge'
    '/v1/recitation/badge'
  );
  return result.data.data;
};
