import React, { ReactNode } from 'react';
import Award2024Img from '../img/Award2024.png';
import conference_thumbnail from '../img/conference_thumbnail.png';
import mobile_device from '../img/mobile_device.png';
import { openNewWindow } from '../../../../api/utils/util-func';
import newGift_top from '../img/newGift-top.png';
import newGift_bottom from '../img/newGift-bottom.png';
import snowflower from '../img/snowflower-line.png';
import logo from '../img/logo-rotate.png';
import { JebsTicket, UnderLine } from '../HomeschoolComp';
import { motion } from 'framer-motion';

const HomeschoolEvent_24_12 = () => {
  return (
    <>
      <motion.section className="bg-black">
        <img src={Award2024Img} alt="Award2024" className="w-full m-auto" />
      </motion.section>
      <div className="flex flex-col gap-10 bg-black text-white px-3 md:px-10 py-20 overflow-x-hidden">
        <section className="relative flex flex-col gap-10">
          <div className="w-3/4 absolute top-0 left-0 transfrom -translate-x-1/3 -translate-y-1/3">
            <PurpleCircle width={'100%'} />
          </div>
          <div className="w-3/4 absolute bottom-0 right-0 transfrom translate-x-1/3 translate-y-1/3">
            <PurpleCircle width={'100%'} />
          </div>
          <motion.div
            className="text-lg md:text-3xl font-extrabold text-center"
            animate={{ opacity: [1, 0.99, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            <div className="text-[#fee0ff]">성공적인 겨울방학!</div>
            <span className="text-[#fee0ff]">신앙교육</span>과{' '}
            <span className="text-[#fee0ff]">영어교육</span> 모두
            <br /> 차별화된 방법으로 시작하면 가능합니다.
          </motion.div>
          <motion.div
            className="text-5xl md:text-8xl font-extrabold text-[#F93EFA] text-center"
            animate={{ opacity: [1, 0.99, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            <span className="text-[#FFE1FF]">12월</span> 홈스쿨
            <br /> 온라인 설명회
          </motion.div>
          <div className="relative w-full max-w-[720px] m-auto">
            <SnowFlower className="w-5 md:w-16 absolute -right-10 -top-10" />

            <img src={mobile_device} alt="conference_thumbnail" />
            <img
              src={conference_thumbnail}
              alt="conference_thumbnail"
              className="p-[10%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          <div className="w-full m-auto text-center " style={{ zIndex: 0 }}>
            <DoubleLineButton
              onClick={() =>
                openNewWindow('https://forms.gle/hYg6Q4YQ4tMcBFb58')
              }
            >
              <div className="text-xl md:text-4xl font-bold p-3 md:p-5">
                12월 온라인 설명회 신청하기
              </div>
            </DoubleLineButton>
            <div className="text-xs md:text-2xl font-bold mt-5">
              (온라인 설명회에서 젭스의 비전과 프로그램 시연을 확인해 보세요!
              <br />
              2024년 마지막 혜택을 받으실 수 있습니다.)
            </div>
          </div>
        </section>

        <section className="relative text-center">
          <SnowFlower className="w-5 absolute top-0 left-0" />
          <div className="text-base md:text-2xl font-bold mt-5 md:mt-10">
            매 년 높아져가는 물가... 교육 시장도 예외는 없습니다.
          </div>
          <div className="text-[#f93efa] text-lg md:text-2xl mt-5">
            피할 수 없는 2025년 홈스쿨 등록비 인상 전
          </div>
          <div className="text-[#f93efa] text-2xl md:text-4xl font-extrabold">
            굿바이 2024, 마지막 최저가 이벤트!
          </div>

          {/* 2025년 2026년 핑크 물음표 */}
          <div className="flex items-center mt-5">
            <div className="w-fit m-auto flex gap-5 md:gap-10">
              <div className="flex flex-col items-center flex-1 mt-10">
                <DottedBorderBox radius={'20px'} rx={20} ry={20} padding={16}>
                  <div className="text-black text-base md:text-2xl font-bold w-full text-nowrap px-4">
                    2025년
                  </div>
                </DottedBorderBox>
                <div className="text-7xl text-[#f93efa] font-bold">?</div>
              </div>
              <div className="w-10 md:w-11 flex items-center rotate-180">
                <DoubleLeftArrow width={'100%'} pathColor="white" />
              </div>
              <div className="flex flex-col items-center flex-1 w-fit">
                <DottedBorderBox radius={'20px'} rx={20} ry={20} padding={16}>
                  <div className="text-black text-base md:text-2xl font-bold w-full text-nowrap px-4">
                    2026년
                  </div>
                </DottedBorderBox>
                <div className="text-7xl text-[#f93efa] font-bold">?</div>
              </div>
            </div>
          </div>
          {/* Chat Bubble 1 */}
          <div className="bg-[#252525] rounded-3xl shadow p-5 md:p-10 mt-5">
            <div className="text-center">
              <span className="text-[#fee0ff] text-xl md:text-3xl font-extrabold">
                2025년 성경적 영어교육을 위한
                <br />
              </span>
              <span className="text-[#f93efa] text-xl md:text-3xl font-extrabold ">
                젭스영어성경홈스쿨 교육비 지원 이벤트{' '}
              </span>
            </div>
            <div className="text-center mt-3 md:mt-8 text-sm md:text-xl font-medium">
              우리 아이 영어 실력에 맞는 다양한 코스 선택지부터
              <br />
              부모와 직접 쌓는 유대감과 신앙 교육까지!
              <br />
              <span className="text-[#fee0ff] text-base md:text-2xl font-extrabold ">
                영어&신앙을 한 번에{' '}
              </span>
              해결하는 이 좋은 구성을
              <br />
              <span className="text-[#fee0ff] text-base md:text-2xl font-extrabold">
                월 4만 원대부터{' '}
              </span>
              시작할 수 있습니다!
            </div>
          </div>

          {/* Chat Bubble 2 */}
          <div className="relative flex justify-end mt-7">
            <SnowFlower
              className="w-7 absolute top-5 left-5"
              delay={0.2}
              duration={8}
            />
            <div className="relative bg-[#252525] rounded-2xl shadow p-5 min-w-fit w-2/3 mb-7 text-base md:text-xl">
              <span className="font-medium ">
                “우리 아이를 성경에 기초한
                <br />
                글로벌 크리스천 리더로 기르고자 하는
                <br />
              </span>
              <span className="font-extrabold">
                ‘마음'만 있다면, 누구나 가능합니다!”
              </span>
              <div className="absolute bottom-0 left-0 trasform translate-x-full translate-y-2/3">
                <Polygon width="20" />
              </div>
            </div>
          </div>

          {/* Chat Bubble 3 */}
          <div className="relative flex justify-start">
            <SnowFlower className="w-7 absolute bottom-7 right-7" delay={1} />
            <div className="relative bg-[#252525] rounded-2xl shadow p-5 min-w-fit w-2/3 mb-7 text-base md:text-xl">
              <div className="font-medium">
                “
                <span className="font-extrabold">
                  젭스가 그 모든 과정을 지원합니다!
                  <br />
                </span>
                지원 받고 맘 편히, 우리 아이를 위한
                <br />
                성경적 영어 교육을 시작하세요!”
              </div>
              <div className="absolute bottom-0 right-0 trasform -translate-x-full translate-y-2/3">
                <Polygon width="20" />
              </div>
            </div>
          </div>

          <div className="text-[#f93efa] text-xl md:text-4xl font-extrabold ">
            이 시대에 꼭 필요한 영어 교육,
            <br />왜 젭스영어성경홈스쿨이어야 할까요?
          </div>
          <div className="text-lg md:text-2xl font-medium mt-5">
            아이에게도 학부모에게도
            <br />
            우리 아이 영어 공부에도 신앙 교육에도,
            <br />
            이만한 교육 콘텐츠는 없습니다.
          </div>

          <div
            className="text-[#f93efa] text-xl md:text-2xl font-semibold underline underline-offset-8 mt-5"
            style={{ textDecorationThickness: 1 }}
          >
            그래서, 젭스가 자신 있게 지원해 드립니다!
          </div>
          <div className="w-fit -rotate-90 m-auto">
            <DoubleLeftArrow width={40} pathColor="#f93efa" bgColor="none" />
          </div>
        </section>

        {/* 선물 트리 */}
        <section className="relative w-full max-w-[520px] m-auto">
          <div className="absolute -top-10 left-1/2 w-full transform -translate-x-1/2">
            <PurpleCircle width="100%" />
          </div>
          <motion.div
            className="max-w-[200px] m-auto relative"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          >
            <motion.img
              src={newGift_top}
              alt="newGift_top"
              className="w-full"
              initial={{ marginBottom: -70, paddingTop: 60 }}
              whileInView={{ marginBottom: -10, paddingTop: 0 }}
            />
            <motion.div
              className="relative w-full m-auto px-5"
              animate={{ scale: [1, 1.05] }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                repeatType: 'reverse',
              }}
            >
              <JebsTicket width={'100%'} />
              <img
                className="absolute w-2/5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={logo}
                alt="jebs-ticket"
              />
            </motion.div>
            <img
              src={newGift_bottom}
              alt="newGift_top"
              className="w-full -mt-2"
            />

            {/* 파티클 */}
            <motion.div
              className="absolute top-[180px] rotate-45 left-[0px] bg-[#ffda36] w-[12px] h-[32px]"
              initial={{ x: 100, y: 44, rotate: 0, opacity: 0 }}
              whileInView={{ x: -8, y: -30, rotate: -45, opacity: 1 }}
              transition={{ type: 'spring', delay: 0.1 }}
            />
            <motion.div
              className="absolute top-[180px] rotate-45 left-[0px] bg-white w-[10px] h-[10px] rounded-full"
              initial={{ x: 100, y: 44, opacity: 0 }}
              whileInView={{ x: -8, y: 20, opacity: 1 }}
              transition={{ type: 'spring' }}
            />
            <motion.div
              className="absolute top-[180px] rotate-45 left-[0px] bg-[#008cff] w-[10px] h-[10px] rounded-full"
              initial={{ x: 100, y: 44, opacity: 0 }}
              whileInView={{ x: 20, y: -55, opacity: 1 }}
              transition={{ type: 'spring' }}
            />
            <motion.div
              className="absolute top-[180px] rotate-45 left-[0px] bg-[#ff5aff] w-[8px] h-[30px]"
              initial={{ x: 10, y: 44, rotate: 0, opacity: 0 }}
              whileInView={{ x: 200, y: 0, rotate: 45, opacity: 1 }}
              transition={{ type: 'spring' }}
            />
            <motion.div
              className="absolute top-[180px] rotate-45 left-[0px] bg-[#008CFF] w-[10px] h-[10px] rounded-full"
              initial={{ x: 10, y: 44, opacity: 0 }}
              whileInView={{ x: 190, y: -20, opacity: 1 }}
              transition={{ type: 'spring', delay: 0.1 }}
            />
            <SnowFlower className="w-5 absolute top-20 -left-20" />
            <SnowFlower className="w-10 absolute -top-10 -right-14" delay={2} />
          </motion.div>

          <Card
            backgroundColor="#f93efa"
            paddingInline={44}
            opacity={1}
            description={
              <>
                영어교육 대가 정철 선생님의 교수법으로,
                <br />
                성경에 기반한 교육 커리큘럼으로!
              </>
            }
            title={'영어와 신앙을 한 번에!'}
          />
          <Card
            backgroundColor="#fe7eff"
            paddingInline={22}
            opacity={0.97}
            description={
              <>
                월 최소 금액 혜택으로 수업이 가능하니까!
                <br />
                다자녀 가정은 교재만 추가하세요!
              </>
            }
            title={'교육비 부담없이 맘 편히!'}
          />
          <Card
            backgroundColor="#ffa6ff"
            paddingInline={0}
            opacity={0.92}
            description={
              <>
                자녀들과 가정에서도,
                <br />
                학생을 모집해 공부방과 교습소로도 운영 가능한!
              </>
            }
            title={'가맹비 0원! 공부방 창업도!'}
          />
        </section>

        <section>
          <div className="bg-[#ececec] rounded-2xl pt-5 pb-3 text-black">
            <div className="text-sm md:text-xl font-bold text-center">
              “사실 처음에는 초기 비용이 부담됐는데 막상 시작해보니
              <br />
              다른 프로그램들과 비교해도
              <br />
              오히려 가성비가 너무 좋다는 생각이 듭니다!”
            </div>
            <div className="text-center text-xs md:text-lg font-medium mt-3">
              -젭스홈스쿨 274기 이*영 선생님-
            </div>
          </div>
          <div className="text-center mt-20 text-3xl md:text-4xl font-extrabold">
            <span className="text-[#f93efa]">
              2024년 마지막 최저가 혜택!
              <br />
            </span>
            <span className="text-white">놓치지 마세요!</span>
          </div>
          <div className="mt-10">
            <DoubleLineButton
              onClick={() =>
                openNewWindow('https://forms.gle/hYg6Q4YQ4tMcBFb58')
              }
            >
              <div className="text-2xl md:text-4xl font-bold p-5">
                홈스쿨 교육비 지원 혜택 받기
              </div>
            </DoubleLineButton>
          </div>
        </section>
      </div>
    </>
  );
};

const Card = ({
  backgroundColor,
  paddingInline,
  opacity,
  description,
  title,
}: {
  paddingInline: number;
  backgroundColor: string;
  opacity: number;
  description: ReactNode;
  title: ReactNode;
}) => (
  <div className="-mt-3 relative" style={{ paddingInline }}>
    <div
      className="rounded-xl text-center m-auto py-6"
      style={{ backgroundColor, opacity }}
    >
      <div className="text-center text-black text-sm md:text-lg font-semibold">
        {description}
      </div>
      <div className="relative text-2xl md:text-3xl leading-relaxed w-fit m-auto font-extrabold pb-0 md:pb-2">
        {title}
        <UnderLine color="#5E6EFF" />
      </div>
    </div>
  </div>
);

const Polygon = ({ width = '53' }: { width?: string }) => (
  <svg
    width={width}
    viewBox="0 0 53 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6405 34.647C29.4424 38.4765 23.5576 38.4765 20.3596 34.647L2.38901 13.1279C-1.96109 7.91878 1.74285 -1.92443e-06 8.52944 -2.51774e-06L44.4706 -5.65981e-06C51.2572 -6.25311e-06 54.9611 7.91876 50.611 13.1279L32.6405 34.647Z"
      fill="#252525"
    />
  </svg>
);

const DoubleLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="border-[2px] md:border-[3px] border-white/60 rounded-[24px] md:rounded-[41px] p-1 md:p-[7px] cursor-pointer"
    onClick={onClick}
  >
    <div className="border-[3px] md:border-[5px] border-white rounded-[17px] md:rounded-[30px] text-center bg-[#5e6eff] hover:bg-[#404cb8]">
      {children}
    </div>
  </div>
);
/**
 * rounded dashed border
 * https://kovart.github.io/dashed-border-generator/
 * @returns
 */
const DottedBorderBox = ({
  radius,
  rx,
  ry,
  padding,
  children,
}: {
  radius: string;
  rx: number;
  ry: number;
  padding: number;
  children: ReactNode;
}) => {
  {
    /*
    
  */
  }
  const svgData = `
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'>
      <rect
        width='100%'
        height='100%'
        fill='none'
        rx='${rx}'
        ry='${ry}'
        stroke='#f93efa'
        stroke-width='12'
        stroke-dasharray='2'
        stroke-dashoffset='0'
        stroke-linecap='butt'
      />
    </svg>
  `;
  const encodedSvg = encodeURIComponent(svgData);
  return (
    <div
      className="relative flex items-center justify-center bg-[#FFFCF8] shadow-lg w-fit"
      style={{
        backgroundImage: `url("data:image/svg+xml,${encodedSvg}")`,
        borderRadius: `${radius}`,
        padding: `${padding}px`,
      }}
    >
      {children}
    </div>
  );
};

const DoubleLeftArrow = ({
  width,
  pathColor = 'black',
  bgColor = '#f93efa',
}: {
  width: number | string;
  pathColor?: string;
  bgColor?: string;
}) => (
  <svg width={width} viewBox="0 0 72 72" fill="none">
    <defs>
      <path
        id="left-arrow"
        d="M36 53.1957L20.4566 37L36 20.913L32.3242 17L13 37L32.3242 57L36 53.1957Z"
      />
    </defs>
    <circle cx="36" cy="36" r="36" fill={bgColor} />
    <motion.g
      clipPath="url(#clip1_1_2)"
      animate={{ opacity: [0, 1, 0.5] }}
      transition={{ repeat: Infinity }}
    >
      <use href="#left-arrow" fill={pathColor} />
    </motion.g>
    <motion.g
      clipPath="url(#clip1_1_2)"
      transform="translate(15,0)"
      animate={{ opacity: [1, 0.5, 0] }}
      transition={{ repeat: Infinity }}
    >
      <use href="#left-arrow" fill={pathColor} />
    </motion.g>
  </svg>
);

const PurpleCircle = ({ width }: { width: number | string }) => (
  <svg width={width} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    {/* 노이즈 효과 필터 */}
    <filter id="noiseFilter" x="0" y="0" width="100%" height="100%">
      <feTurbulence
        type="fractalNoise"
        baseFrequency="1"
        numOctaves="3"
        result="noise"
      />
      <feColorMatrix
        in="noise"
        type="matrix"
        values="0 0 0 0 0  
              0 0 0 0 0  
              0 0 0 0 0  
              0 0 0 1 0"
        result="alphaNoise"
      />
      <feBlend in="SourceGraphic" in2="alphaNoise" mode="overlay" />
    </filter>

    {/* 보라색 그라데이션 원 */}
    <radialGradient id="purpleGradient" cx="50%" cy="50%" r="50%">
      <stop offset="0%" stopColor="purple" stopOpacity="1" />
      {/* <stop offset="50%" stopColor="purple" stopOpacity="0.3" /> */}
      <motion.stop
        offset="50%"
        stopColor="purple"
        // stopOpacity="0.6"
        animate={{
          stopOpacity: [0.6, 0.3, 0.6],
        }}
        transition={{ repeat: Infinity, duration: 3, delay: 1 }}
      />
      <stop offset="100%" stopColor="purple" stopOpacity="0" />
    </radialGradient>

    {/* 원 */}
    <circle
      cx="100"
      cy="100"
      r="80"
      fill="url(#purpleGradient)"
      filter="url(#noiseFilter)"
    />
  </svg>
);

const SnowFlower = ({
  className,
  delay = 0,
  duration = 5,
}: {
  className: string;
  delay?: number;
  duration?: number;
}) => (
  <motion.img
    src={snowflower}
    alt="snowflower"
    className={className}
    animate={{ y: [0, 100, 100], rotate: [0, 180], opacity: [0, 1, 0] }}
    transition={{ repeat: Infinity, ease: 'linear', duration, delay }}
  />
);
export default HomeschoolEvent_24_12;
