import React, { ReactNode } from 'react';
import { CheckIcon, DiamondIcon } from './HomeschoolComp';
import { motion } from 'framer-motion';

const HomeschoolSection5 = () => {
  return (
    <div className="flex flex-col items-center bg-[#ffd973] p-4 md:p-10 rounded-t-3xl py-20">
      <section className="flex flex-col items-center text-3xl text-white font-bold drop-shadow-[2px_2px_2px_rgba(0,0,0,0.2)]">
        <DiamondIcon color="white" width={24} />
        <div className="text-center mt-2">
          젭스영어성경홈스쿨
          <br />
          코스 TIP
        </div>
      </section>
      <section className="flex flex-col gap-4 mt-10">
        <Card textArr={['아직 영어 노출 전인 미취학 아이라면, KIDS 추천!']}>
          <div className="flex flex-col gap-2 bg-gray1 p-4 text-sm mt-4 rounded-lg">
            <div className="flex relative">
              <span className="text-[#ff3f00] font-bold mr-1">KIDS</span> 2년
              <Progress type="kids" />
            </div>
            <div className="flex relative">
              <span className="text-[#ff9b00] font-bold mr-1">BASIC</span> 4년
              <Progress type="basic" />
            </div>
            <div className="flex relative">
              <span className="text-[#309c5b] font-bold mr-1">ADVANCED</span>{' '}
              2년
              <Progress type="advanced" />
            </div>
          </div>
        </Card>
        <Card textArr={['8세 이상이라면, 무조건 BASIC 추천!']} />
        <Card textArr={['우리말이 빠른 미취학 아동이라면, BASIC 추천!']} />
        <Card textArr={['ADVANCED는 중학생 이상, 성인까지도 가능!']} />
      </section>
    </div>
  );
};

const Progress = ({ type }: { type: 'kids' | 'basic' | 'advanced' }) => {
  return (
    <div className="flex w-[180px] absolute right-0 top-0">
      <span
        className="grow h-5"
        style={{ backgroundColor: type === 'kids' && '#ff3f00' }}
      />
      <span
        className="grow-[2]"
        style={{ backgroundColor: type === 'basic' && '#ff9b00' }}
      />
      <span
        className="grow"
        style={{ backgroundColor: type === 'advanced' && '#309c5b' }}
      />
    </div>
  );
};

const Card = ({
  textArr,
  children,
}: {
  textArr: string[];
  children?: ReactNode;
}) => {
  return (
    <motion.div
      className="flex flex-col bg-white rounded-xl p-4 shadow-lg font-medium"
      initial={{ x: -20 }}
      whileInView={{ x: 0 }}
    >
      <div className="flex gap-2 items-start">
        <CheckIcon width={20} />
        <div className="text-sm">
          {textArr.map((text, idx) => (
            <div key={idx}>{text}</div>
          ))}
        </div>
      </div>
      {children}
    </motion.div>
  );
};

export default HomeschoolSection5;
