import { ColumnsType } from 'antd/es/table';
import { recitationResultType } from '../../../constants/types/lms-type';
import { PaginationProps } from '../../../constants/types';
import { STUDENT_STATUS } from '../../../data/statics-datas';
import HFbutton from '../../common/input/HFbutton';
import RecitationStudentColumnLevel from './RecitationStudentColumnLevel';

const RecitationStudentColumns = (
  pageInfo: PaginationProps,
  navigate: (url: string) => void
): ColumnsType<recitationResultType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, __, index) =>
      (pageInfo.page - 1) * pageInfo.pageSize + index + 1,
    align: 'center',
  },
  {
    title: '이름',
    key: 'name',
    render: (_, row) => row.user.name,
    align: 'center',
  },
  {
    title: '아이디',
    key: 'uid',
    render: (_, row) => row.user.uid,
    align: 'center',
  },
  {
    title: '반',
    key: 'uid',
    render: (_, row) => row.user.classInfo.classGroup?.name,
    align: 'center',
  },
  {
    title: '상태',
    key: 'uid',
    render: (_, row) => STUDENT_STATUS[row.user.classInfo.studentStatus],
    align: 'center',
  },
  {
    title: '인증제 요약',
    children: [
      {
        title: '레벨',
        key: 'level_list',
        render: (_, row) => (
          <RecitationStudentColumnLevel
            levels={row.recitationResultSummary.completedLevels}
          />
        ),
        align: 'center',
      },
      {
        title: '합격',
        key: 'passCount',
        render: (_, row) => (
          <div className="text-green-500">
            {row.recitationResultSummary.passCount}
          </div>
        ),
        align: 'center',
      },
      {
        title: '불합격',
        key: 'failCount',
        render: (_, row) => (
          <div className="text-red-500">
            {row.recitationResultSummary.failCount}
          </div>
        ),
        align: 'center',
      },
      {
        title: '연습',
        key: 'testCount',
        render: (_, row) => <div>{row.recitationResultSummary.testCount}</div>,
        align: 'center',
      },
    ],
  },
  {
    title: '상세보기',
    key: 'detail',
    render: (_, row) => (
      <HFbutton onClick={() => navigate('./' + row.user.uid)}>
        상세보기
      </HFbutton>
    ),
    align: 'center',
    width: 100,
  },
];

export default RecitationStudentColumns;
