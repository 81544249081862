import { TableColumnsType, Tooltip } from 'antd';
import HFbutton from '../../../../components/common/input/HFbutton';
import { TODO_SUBTYPE } from '../../../../data/statics-datas';
import HFLink from '../../../../components/common/input/HFLink';
import { LmsStudentProps, PaginationProps } from '../../../../constants/types';
import {
  LmsTodoStatusByIdType,
  LmsTodoStatusByTodoType,
} from '../../../../constants/types/lms-type';
import TodoStatusDeleteBtn from '../../../../components/lms/todo/TodoStatusDeleteBtn';
import { ColumnsType } from 'antd/es/table';
import { PencilIcon } from '@heroicons/react/24/outline';

type TodoListType = {
  id: number;
  book: string;
  lesson: string;
  code: string;
  title: string;
  link: string;
  type: string;
};

export const columnsBase = (
  action: (title: string, url: string) => void
): TableColumnsType<TodoListType> => [
  {
    title: '자료유형',
    key: 'type',
    align: 'center',
    render: (_, row) =>
      TODO_SUBTYPE.find((item) => item.subType === row.type)?.name || row.type,
  },
  {
    title: '미리보기',
    key: 'preview',
    align: 'center',
    render: (_, row) => (
      <HFbutton
        type="Outlined"
        height={34}
        onClick={() => action(row.title, row.link)}
      >
        미리보기
      </HFbutton>
    ),
    width: 120,
  },
  {
    title: '등록',
    key: 'regist',
    align: 'center',
    render: (_, row) => (
      <HFLink
        to=""
        getParams={[
          { key: 'stage', value: 'regist' },
          { key: 'type', value: row.type },
          { key: 'ids', value: [row.id].toString() },
        ]}
        // to={`?stage=regist&type=${row.type}&ids=${[row.id]}`}
        body="할 일 등록"
        height={34}
      />
    ),
    width: 120,
  },
];

export const columnsRECITATION = (
  action: (title: string, url: string) => void
): TableColumnsType<TodoListType> => [
  {
    title: '북',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '레벨',
    dataIndex: 'book',
    key: 'book',
    align: 'center',
  },
  ...columnsBase(action),
];

export const columnNoLesson = (
  action: (title: string, url: string) => void
): ColumnsType<TodoListType> => [
  {
    title: '북/챕터',
    dataIndex: 'book',
    key: 'book',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  ...columnsBase(action),
];

export const columnsFull = (
  action: (title: string, url: string) => void
): TableColumnsType<TodoListType> => [
  {
    title: '북/챕터',
    dataIndex: 'book',
    key: 'book',
    align: 'center',
  },
  {
    title: '레슨',
    dataIndex: 'lesson',
    key: 'lesson',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  ...columnsBase(action),
];

export const columnsStudent: TableColumnsType<LmsStudentProps> = [
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '아이디',
    dataIndex: 'uid',
    key: 'uid',
    align: 'center',
  },
  {
    title: '반',
    key: 'schoolGroup',
    align: 'center',
    render: (_, row) =>
      row.classInfos.map((item) => item.classGroup?.name).join(', '),
  },
];

interface TodoStatusBtnProps {
  classId: string;
  subType: string;
  todoState: string;
  page: number;
  pageSize: number;
  editHandler: (data: LmsTodoStatusByTodoType) => void;
  openPrev: (title: string, url: string) => void;
  reload: () => void;
}

const stateColumnsByTodoRECITATION: TableColumnsType<LmsTodoStatusByTodoType> =
  [
    {
      title: '레벨',
      key: 'level',
      render: (_, row) => row.txInfo.book,
      align: 'center',
    },
    {
      title: '북',
      key: 'book',
      render: (_, row) => row.txInfo.title,
      align: 'center',
    },
  ];

const stateColumnsByTodoBase: TableColumnsType<LmsTodoStatusByTodoType> = [
  {
    title: '북/챕터',
    key: 'book',
    render: (_, row) => row.txInfo.book,
    align: 'center',
  },
  {
    title: '제목',
    key: 'title',
    render: (_, row) => row.txInfo.title,
    align: 'center',
  },
];

export const stateColumnsByTodoWithLesson: TableColumnsType<LmsTodoStatusByTodoType> =
  [
    {
      title: '북/챕터',
      key: 'book',
      render: (_, row) => row.txInfo.book,
      align: 'center',
    },
    {
      title: '레슨',
      key: 'lesson',
      render: (_, row) => row.txInfo.lesson,
      align: 'center',
    },
    {
      title: '제목',
      key: 'title',
      render: (_, row) => row.txInfo.title,
      align: 'center',
    },
  ];

const selectColumnsByTodo = (
  subType: string
): TableColumnsType<LmsTodoStatusByTodoType> => {
  switch (subType.toUpperCase()) {
    case 'RECITATION':
      return stateColumnsByTodoRECITATION;
    case 'JEBSPL':
      return stateColumnsByTodoBase;
    default:
      return stateColumnsByTodoWithLesson;
  }
};

export const stateColumnsByTodo = ({
  classId,
  subType,
  todoState,
  page,
  pageSize,
  editHandler,
  openPrev,
  reload,
}: TodoStatusBtnProps): ColumnsType<LmsTodoStatusByTodoType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, row, index) => index + (page - 1) * pageSize + 1,
    align: 'center',
  },
  {
    title: '할일명',
    key: 'index',
    render: (_, row, index) =>
      TODO_SUBTYPE.find((item) => item.subType === row.txInfo.type)?.name ||
      row.txInfo.type,
    align: 'center',
  },
  ...selectColumnsByTodo(subType),
  {
    title: '시작일',
    key: 'fromDate',
    render: (_, row) => new Date(row.txInfo.fromDate).toLocaleDateString(),
    align: 'center',
  },
  {
    title: '마감일',
    key: 'toDate',
    render: (_, row) =>
      todoState === 'IN_PROGRESS' || todoState === 'NOT_STARTED' ? (
        <Tooltip title="마감일 수정">
          <div
            className="flex items-center justify-center gap-2 cursor-pointer"
            onClick={(e) => editHandler(row)}
          >
            {new Date(row.txInfo.toDate).toLocaleDateString()}
            <span className="animate-bounce">
              <PencilIcon className="w-3 h-3" />
            </span>
          </div>
        </Tooltip>
      ) : (
        new Date(row.txInfo.toDate).toLocaleDateString()
      ),
    align: 'center',
  },
  {
    title: '미리보기',
    key: 'preview',
    width: 120,
    render: (_, row) => (
      <HFbutton
        type="Outlined"
        height={34}
        onClick={() => openPrev(row.txInfo.title, row.txInfo.link)}
      >
        미리보기
      </HFbutton>
    ),
    align: 'center',
  },
  {
    title: '삭제',
    key: 'delete',
    width: 120,
    render: (_, row) => (
      <TodoStatusDeleteBtn
        classId={classId}
        txId={row.txId}
        todoState={todoState}
        reload={reload}
      />
    ),
    align: 'center',
  },
];

export const stateColumnsById = (
  pageInfo: PaginationProps
): ColumnsType<LmsTodoStatusByIdType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, row, index) =>
      index + (pageInfo.page - 1) * pageInfo.pageSize + 1,
    align: 'center',
  },
  {
    title: '학생명',
    key: 'name',
    render: (_, row) => row.name,
    align: 'center',
  },
  {
    title: '아이디',
    key: 'uid',
    render: (_, row) => row.uid,
    align: 'center',
  },
  {
    title: '누적 할 일',
    key: 'totalTodo',
    render: (_, row) => row.accumulatedTodoCount,
    align: 'center',
  },
  {
    title: '누적 뱃지',
    key: 'totalBadge',
    render: (_, row) => row.accumulatedBadgeCount,
    align: 'center',
  },
];
