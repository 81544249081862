import axios from 'axios';
import { onReqError, onRequest } from './onRequest';
import { onResError, onResponse } from './onResponse';
const cors = require('cors');

const instance = axios.create({
  baseURL: process.env.REACT_APP_JEBS_API_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(onRequest, onReqError);
instance.interceptors.response.use(onResponse, onResError);

export default instance;
