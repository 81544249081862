import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { studentRecitationCerificateType } from '../../constants/types/student-type';
import IconAward from '../../pages/student/certification/IconAward';
import { openModal } from '../../store/features/modalSlice';
import { useAppDispatch } from '../../store/hook';
import TextWinds from '../common/TextWinds';
import ModalRecitationCertificate from '../modal/ModalRecitationCertificate';
import dayjs from 'dayjs';
import {
  getRecitationCertificates,
  getRecitation,
  postRecitationCertificates,
} from '../../api/utils/api/recitationApi';
import {
  RecitationListType,
  RecitationLevelType,
  RecitationType,
} from '../../constants/types/recitation-type';

interface RecitationResultProps {
  studentUid: string;
}
const RecitationResult = ({ studentUid }: RecitationResultProps) => {
  const [data, setData] = useState<RecitationListType>();
  const [certificateList, setCertificateList] = useState<
    studentRecitationCerificateType[]
  >([]);

  const dataLoad = async () => {
    const userid = studentUid;
    if (!userid) throw new Error('userid is null');
    const certificate = await getRecitationCertificates(userid);
    const recitation = await getRecitation(userid);
    return { certificate, recitation };
  };

  useEffect(() => {
    dataLoad().then(({ certificate, recitation }) => {
      setData(recitation);
      setCertificateList(certificate);
    });
  }, []);

  return (
    <>
      {data?.recitationProgress?.map((level) => (
        <StudentCertificatioinLevel
          key={level.groupLevel}
          level={level}
          studentProfile={data.studentInfo}
          certificate={certificateList?.find(
            (certificate) =>
              certificate.type === level.type &&
              certificate.subType === level.subType
          )}
        />
      ))}
    </>
  );
};

/**
 * 하나의 레벨. 인증서 관리.
 * @param level 레벨 정보
 * @param certificate 인증서 정보. 없으면 인증서 발급 버튼 표시
 * @returns
 */
const StudentCertificatioinLevel = ({
  level,
  studentProfile,
  certificate,
}: {
  level: RecitationLevelType;
  studentProfile: {
    uid: string;
    name: string;
    englishName: string;
  };
  certificate?: studentRecitationCerificateType;
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] =
    useState<studentRecitationCerificateType>(certificate);

  // 레벨 페스 확인
  const checkBadge = !level.results
    ?.map((book) => (book.passDate ? true : false))
    .includes(false);

  /**
   * 인증서 상태
   * none : 인증서 발급 불가 [ 레벨 페스 미달 ]
   * ready : 인증서 발급 가능
   * done : 인증서 발급 완료
   */
  const [state, setState] = useState<'none' | 'ready' | 'done'>(
    certificate ? 'done' : checkBadge ? 'ready' : 'none'
  );

  const openCertificateModal = (data: studentRecitationCerificateType) => {
    dispatch(
      openModal({
        body: (
          <ModalRecitationCertificate
            englishName={data.userEnglishName}
            subject={data.subType}
            date={data.createdAt}
          />
        ),
      })
    );
  };

  // 인증서 발급 버튼 클릭
  const handleIssueCertificate = () => {
    console.log(studentProfile);
    postRecitationCertificates({
      uid: studentProfile.uid,
      name: studentProfile.name,
      englishName: studentProfile.englishName,
      type: level.type,
      subType: level.subType,
    }).then((data) => {
      setState('done');
      setData(data);
      openCertificateModal(data);
    });
  };

  return (
    <div className="flex flex-col lg:flex-row gap-3 mt-4 items-center">
      {/* <TextWinds type={'title_h3'} children={`Level ${level.groupLevel}`} /> */}
      <motion.img
        src={
          process.env.PUBLIC_URL +
          `/images/student/badge/recitation/LV${level.groupLevel}.png`
        }
        draggable={false}
        className={`h-[100px] ${checkBadge ? '' : 'filter grayscale'}`}
        whileTap={checkBadge ? { rotate: [0, 360] } : { x: [-0.5, 0.5] }}
        // whileInView={checkBadge ? { scale: 1.05 } : {}}
        transition={{
          repeat: Infinity,
          duration: 0.1,
          repeatType: 'mirror',
          ease: 'easeInOut',
        }}
      />
      <div className="w-full">
        <div className="flex gap-2 items-center justify-between lg:justify-start">
          <TextWinds type="title_h3">LEVEL {level.groupLevel}</TextWinds>
          {checkBadge && (
            <button
              onClick={
                state === 'done'
                  ? () => openCertificateModal(data)
                  : state === 'ready'
                  ? handleIssueCertificate
                  : undefined
              }
              className="text-sm text-skyblue border border-skyblue rounded-lg px-1 p-[2px] hover:bg-gray2"
            >
              {state === 'done' ? (
                <>
                  인증서 보기{' '}
                  <DocumentArrowDownIcon className="w-4 h-4 inline" />
                </>
              ) : state === 'ready' ? (
                '인증서 발급'
              ) : (
                ''
              )}
            </button>
          )}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
          {level.results.map((book) => (
            <StudentCertificationBook key={book.title} book={book} />
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * 인증제 하나. book {n}
 * @param book 북 정보. 패스 여부
 * @returns
 */
const StudentCertificationBook = ({ book }: { book: RecitationType }) => {
  const onoff = book.passDate ? true : false;
  // const onoff = true;

  return (
    <div className="flex flex-col">
      <div
        className={`w-full text-center shadow-card rounded-lg py-3 text-lg font-bold ${
          onoff ? 'bg-spotLilac text-white' : 'bg-gray-100 text-gray-500'
        }`}
        // onClick={() => {
        //   alert('TODO : 버튼인지 보는 용도인지 확인 필요');
        // }}
      >
        <div
          className={`flex flex-col sm:flex-row items-center justify-center gap-2`}
        >
          {onoff && <IconAward />}
          {book.title}
        </div>
      </div>

      <TextWinds
        type="content_body3"
        color="gray7"
        className="text-center mt-1"
      >
        {book.passDate
          ? '합격 : ' + dayjs(book.passDate).format('YYYY-MM-DD')
          : book.failDate
          ? '불합격 : ' + dayjs(book.failDate).format('YYYY-MM-DD')
          : book.testDate
          ? '연습 : ' + dayjs(book.testDate).format('YYYY-MM-DD')
          : ''}
      </TextWinds>
    </div>
  );
};

export default RecitationResult;
