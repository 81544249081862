import React, { useCallback, useEffect, useState } from 'react';
import OrdererInfo from '../../../components/purchase/ordererinfo';
import TextWinds from '../../../components/common/TextWinds';
import NormalPage from '../../../components/layout/NormalPage';
import FullWidthPage from '../../../components/layout/FullWidthPage';
import DeliveryInfo from '../../../components/purchase/DeliveryInfo';
import OrderProductInfo from '../../../components/purchase/orderproductinfo';
import MemberShipInfo from '../../../components/purchase/membershipinfo';
import AmountPayment from '../../../components/purchase/amountpayment';
import Payments from '../../../components/purchase/payments';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
  getPurchaseInfo,
  PurchaseRequestDto,
  reqPurchaseRequest,
  resetPurchaseInfo,
  selectPurchaseInfo,
} from '../../../store/features/purchaseSlice';
import { useLocation } from 'react-router-dom';
import {
  getDiscountAmount,
  getOrderItemTotalAmount,
} from '../../../api/utils/order-item-total-amount';
import { getPoint } from '../../../store/features/pointSlice';
import { Device, PaymentMethod, ProductInstallmentType } from '../../../enum';
import { purchase_valid } from '../../../api/utils/purchase-validation';

import { selectDeliveryInfo } from '../../../store/features/orderInfoSlice';
import { OrderItem } from '../../../constants/types';
import { openAlert, openModal } from '../../../store/features/modalSlice';
import { AgreementType } from '../../../components/common/Agreement';
import PurchaseFinalModal from '../../../components/purchase/PurchaseFinalModal';
import { PurchaseSummaryType } from '../../../components/purchase/PurchaseSummary';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';

const Purchase = () => {
  const templateId = (useLocation().state as { purchaseId?: string })
    ?.purchaseId;

  if (!templateId) window.location.href = '/';

  const path = '/v1/order/template/' + templateId;
  const dispatch = useAppDispatch();

  // 최종 계산에 적용된 포인트
  const [pointAmount, setPointAmount] = useState(0);
  // 전체 동의 체크박스. 왜 여기 있는지 확인 필요.
  const [selectAll, setSelectAll] = useState(false);
  // 결제 진행중
  const [isPayment, setIsPayment] = useState(false);
  /** 결제 약관 동의 */
  const [paymentTermAgree, setPaymentTermAgree] = useState([
    { id: 'agree_1', label: AgreementType.REFUND, isChecked: false },
  ]);

  const purchaseInfo = useAppSelector(selectPurchaseInfo);

  useEffect(() => {
    dispatch(resetPurchaseInfo());
    dispatch(getPoint({ url: '/v1/point' }));

    dispatch(getPurchaseInfo({ url: path }))
      .unwrap()
      .then((res) => {});
  }, []);

  const handleCheckboxChange = (targetObj: any) => {
    const modifiedArray = paymentTermAgree.map((obj) =>
      obj.id === targetObj.id ? targetObj : obj
    );

    setPaymentTermAgree(modifiedArray);

    modifiedArray.every((item: any) => item.isChecked)
      ? setSelectAll(true)
      : setSelectAll(false);
  };

  const handleSelectAllChange = (e: any) => {
    setPaymentTermAgree(e);
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    onChangePrices();
  }, [purchaseInfo]);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [totalDeliveryAmount, setTotalDeliveryAmount] = useState(3000);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const onChangePrices = () => {
    let productAmount = 0,
      discountAmount = 0;
    purchaseInfo?.order_items.map((item: OrderItem) => {
      // 할인금액
      discountAmount += getDiscountAmount('purchase', item);
      // 상품금액
      productAmount += getOrderItemTotalAmount(item);
      // 배송비
    });

    setTotalProductAmount(productAmount);
    setTotalDiscountAmount(discountAmount);
    setTotalDeliveryAmount(
      purchaseInfo?.shipping_fee ? purchaseInfo.shipping_fee : 0
    );
  };

  /** 결제방식 선택 */
  const [paymentMethod, setPaymentMethod] = useState('');
  const handlePaymentMethod = (id: string) => {
    setPaymentMethod(id);
  };

  const handleOpenFull = (id: string, toggled: boolean) => {
    const changeItem = {
      ...paymentTermAgree.find((el) => el.id === id),
      views: toggled,
    };
    handleCheckboxChange(changeItem);
  };

  /**
   * 결제하기(PG사)
   */
  const deliveryInfo = useAppSelector(selectDeliveryInfo);
  const isMobileDevice = () => {
    const mobileDevices = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/;
    return mobileDevices.test(navigator.userAgent.toLowerCase());
  };

  const handleOpenPayment = useCallback(async () => {
    /**
     * Validation
     * 1. 배송지 정보
     * 2. 결제방식 선택
     */
    const param: PurchaseRequestDto = {
      device: isMobileDevice() ? Device.MOBILE : Device.PC,
      address_1: deliveryInfo?.address,
      address_2: deliveryInfo?.detailAddress,
      message: '안전하게 배송해주세요!',
      point_used: pointAmount,
      contact_no: deliveryInfo?.phone,
      payment_method: paymentMethod as PaymentMethod,
      post_code: deliveryInfo?.zipCode,
      receiver: deliveryInfo?.name,
      template_id: templateId,
      installment_type:
        purchaseInfo.order_items.length === 1
          ? purchaseInfo.order_items[0].product.installment_type
          : ProductInstallmentType.STANDARD,
    };

    // TODO : PG사 호출을 위한 validation 필요
    const { flag, message } = purchase_valid(param);
    if (!flag) {
      dispatch(openAlert({ message: message }));
    } else {
      try {
        const res = await dispatch(
          reqPurchaseRequest({
            url: '/v1/order/master/purchase-request',
            data: param,
          })
        ).unwrap();

        // `결제화면 PG사 호출 결과 : ${res.authPageUrl}`
        return res.authPageUrl;
      } catch (e) {
        console.error(
          '일시적인 장애로 상품을 구매할 수 없습니다. 나중에 다시 시도해주세요.'
        );
        return false;
      }
    }
  }, [pointAmount, paymentMethod, deliveryInfo]);

  const onPurchase = (pgUrl: string) => {
    localStorage.setItem('templateId', templateId);
    window.location.href = pgUrl;
  };

  const openConfirm = () => {
    handleOpenPayment().then((res) => {
      if (res) {
        setIsPayment(true);
        const purchaseInfo: PurchaseSummaryType = {
          productAmount: totalProductAmount,
          deliveryCharge: totalDeliveryAmount,
          discountAmount: totalDiscountAmount,
          pointAmount: pointAmount,
        };
        dispatch(
          openModal({
            body: (
              <PurchaseFinalModal
                purchaseInfo={purchaseInfo}
                pgUrl={res}
                closeAction={() => setIsPayment(false)}
                purchaseAction={onPurchase}
              />
            ),
          })
        );
      }
    });
  };

  return (
    templateId && (
      <NormalPage bgColor="white" xlBgColor="gray0" paddingX={0}>
        <FullWidthPage bgColor="transparent">
          <section className="pt-[32px] pb-[24px] hidden xl:flex">
            <TextWinds type="title_h1">결제</TextWinds>
          </section>

          <section className="flex flex-col gap-[24px] pt-[25px] xl:flex-row">
            <div className="flex flex-row justify-center w-full xl:basis-3/4">
              {purchaseInfo && (
                <div className="relative w-full h-full">
                  <OrdererInfo />
                  <DeliveryInfo />
                  <OrderProductInfo purchaseInfo={purchaseInfo} />
                  <MemberShipInfo
                    originPrice={
                      totalProductAmount +
                      totalDeliveryAmount -
                      totalDiscountAmount
                    }
                    setPointAmount={setPointAmount}
                  />

                  <Payments
                    paymentTermAgree={paymentTermAgree}
                    selectAll={selectAll}
                    paymentMethod={paymentMethod}
                    handleSelectAllChange={handleSelectAllChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleOpenFull={handleOpenFull}
                    handlePaymentMethod={handlePaymentMethod}
                  />
                </div>
              )}
            </div>

            <div className="w-full xl:basis-1/4 t-0">
              <AmountPayment
                productAmount={totalProductAmount}
                deliveryAmount={totalDeliveryAmount}
                discountAmount={totalDiscountAmount}
                pointAmount={pointAmount}
                paymentMethod={paymentMethod}
                handleOpenPayment={openConfirm}
                selectAll={selectAll}
              />
            </div>
          </section>

          {isPayment && (
            <div className="fixed flex justify-center  top-0 left-0 bg-gray2 w-full h-full opacity-80">
              <div className="flex flex-col gap-5 mx-auto w-fit py-10 items-center justify-center">
                <LoadingSpinner />
                <div>결제 진행중입니다.</div>
              </div>
            </div>
          )}
        </FullWidthPage>
      </NormalPage>
    )
  );
};

export default Purchase;
