import { ApiResponse } from '../../../constants/types';
import { RecitationListType } from '../../../constants/types/recitation-type';
import { studentRecitationCerificateType } from '../../../constants/types/student-type';
import instance from '../../axios/utils.ts/instance';

/**
 * 인증제 내역 조회
 * @returns
 */
export const getRecitation = async (uid: string) => {
  const response = await instance.get<
    ApiResponse<RecitationListType>
    // >('v1/student/recitation');
  >('v1/recitation', { params: { uid } });
  return response.data.data;
};

/**
 * 인증서 목록 조회
 * @returns
 */
export const getRecitationCertificates = async (uid: string) => {
  // const response = await instance.get('v1/student/certificates');
  const response = await instance.get('v1/recitation/certificates', {
    params: { uid },
  });
  return response.data.data;
};

/**
 * 학생 인증서 발급
 * @param params
 * @returns
 */
export const postRecitationCertificates = async (params: {
  uid: string;
  name: string;
  englishName: string;
  type: string;
  subType: string;
}) => {
  const response = await instance.post<
    ApiResponse<studentRecitationCerificateType>
    // >('v1/student/certificates', params);
  >('v1/recitation/certificates', params);
  return response.data.data;
};

/**
 * 학생 인증서 조회
 */
export const getCertificate = async (certificateId: string) => {
  const result = await instance.get<
    ApiResponse<studentRecitationCerificateType>
  >(`/v1/recitation/certificates/${certificateId}`);
  return result.data.data;
};
